export function store(data) {
    return window.$axios.post(`${window.service.api}/custom_briefing/store`, data);
}

export function lists(params = {}) {
    return window.$axios.get(`${window.service.api}/custom_briefing/lists`, { params });
}

export function find(params = {}) {
    return window.$axios.get(`${window.service.api}/custom_briefing/find`, { params });
}

export function drop(params = {}) {
    return window.$axios.delete(`${window.service.api}/custom_briefing/`, { params });
}
